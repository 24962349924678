:root {
  --bg: #101010;
  --text: #fff;
  --text-secondary: #a2a2a2;
  --accent: #bb4b4b;
  --hover-interact: #555555;
}

@import url("https://fonts.googleapis.com/css2?family=Ubuntu+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap");

html,
body {
  background-color: var(--bg);
  color: var(--text);
  height: fit-content;
}

p,
h2,
h3 {
  font-family: "Ubuntu Mono", monospace;
  margin: 0;
  padding: 0.2rem 0.2rem 0 0.2rem;
  text-align: left;
}

.AppGrid {
  padding: 5rem;
  display: grid;

  grid-template-areas:
    "information divider projects"
    "tech divider projects"
    "divider3 divider projects"
    "contactme divider projects";

  grid-template-columns: 44% 12% 44%;
}

.GridInfoArea {
  grid-area: information;
}

.GridProjectsArea {
  grid-area: projects;
}

.ContactMeArea {
  grid-area: contactme;
}

.GridTechnologyArea {
  grid-area: tech;
}

.App {
  text-align: center;
  padding: 5rem;
}

.HalfNHalf {
  display: grid;
  grid-template-columns: 44% 12% 44%;
  height: fit-content;
}

.LeftContainer {
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
}

.BigFatTypewriterName {
  text-align: left;
  font-size: 3.5rem;
  letter-spacing: 0.4rem;
  font-weight: 700;
  padding-bottom: 0.5rem;
}

.LittleSmallUndernameTitle {
  text-align: left;
  font-size: 1.5rem;
  color: var(--text-secondary);
  font-weight: 400;

  text-decoration-line: underline;
  text-decoration-color: var(--accent);
}

.IntroductionUnderTitle {
  text-align: left;
  font-size: 1.85rem;
  color: var(--text);
  font-weight: 400;
  text-align: left;
  line-height: 2.075rem;
}

.RedUnderline {
  text-decoration-line: underline;
  text-decoration-color: var(--accent);
}

.LinksBoutSelf {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 3rem;
}

.LinksBoutSelf > a {
  color: #a2a2a2;
  transition: all 0.25s;
  font-size: 2.25rem;

  &:hover {
    color: var(--accent);
    transform: scale(1.1);
    transition: all 0.25s;
  }
}

.Header {
  text-align: left;
  font-weight: 700;
  text-decoration: underline;
  text-decoration-color: var(--accent);
}

.CenterDivider {
  position: relative;
  background-color: rgba(255, 255, 255, 0.1);
  width: 0.2rem;
  border-radius: 5rem;
  height: 100%;
  margin: auto;

  transition: height 0.25s;

  grid-area: divider;
}

.TechDivider {
  display: none;
}

.ContactDivider {
  display: block;
  width: 100%;
  height: 0.2rem;

  position: relative;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5rem;

  transition: width 0.25s;
  margin-top: 3.5rem;
  grid-area: divider3;
}

.ProjectListContainer {
  max-width: 40rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  height: auto;
}

.ProjectsHeader {
  display: none;
}

.ProjectName {
  text-decoration: underline var(--accent);
  font-size: 1.35rem;
}

.ProjectDescription {
  color: var(--text-secondary);
}

.TechUsedContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.75rem;
}

.HaveIdea {
  font-size: 1.75rem;
  color: var(--text-secondary);
  font-weight: 400;
  margin-bottom: 0px;
}

.LetsChat {
  text-decoration: underline var(--accent);
  margin-top: 0rem;
  font-size: 2.5rem;
}

.ContactMeInputs {
  display: grid;
  grid-template-areas: "email name" "message message";
  gap: 1rem;
}

.MessageSent {
  color: var(--text-secondary);
  font-weight: 400;
  margin-bottom: 1rem;
}

.AnotherMessage {
  color: var(--text-secondary);
  cursor: pointer;
  font-size: 1.25rem;
  transition: color 0.5s;
  margin: 0;

  &:hover {
    color: var(--text);
    text-decoration: underline;
    text-decoration-color: var(--text-secondary);

    transition: color 0.5s;
  }
}

@media screen and (max-width: 800px) {
  .TechUsedContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 700px) {
  .TechUsedContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 600px) {
  .TechUsedContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 400px) {
  .TechUsedContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

/* This is all stuff for mobile */
@media screen and (max-width: 800px) {
  .AppGrid {
    grid-template-areas:
      "information"
      "divider"
      "projects"
      "divider2"
      "tech"
      "divider3"
      "contactme";

    grid-template-columns: 100%;
    padding: 2rem;
  }

  .ContactMeInputs {
    display: grid;
    grid-template-areas: "email" "name" "message";
    gap: 1rem;
  }

  .CenterDivider {
    width: 100%;
    height: 0.2rem;

    transition: width 0.25s;
    margin-top: 1.5rem;
  }

  .TechDivider {
    display: block;
    width: 100%;
    height: 0.2rem;

    position: relative;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5rem;

    transition: width 0.25s;
    margin-top: 3.5rem;
    grid-area: divider2;
  }

  .ProjectsHeader {
    display: block;
  }
}
