.TechCard {
  width: 6rem;
  height: 6rem;
  border: 3px solid #343434;
  border-radius: 0.75rem;
  color: var(--text-secondary);
  font-size: 3rem;
  justify-content: center;
  align-items: center;
  display: flex;
  transition: all 0.5s;

  &:hover {
    border: 3px solid var(--hover-interact);
    color: var(--accent);
    transform: scale(1.05);

    transition: all 0.5s;
  }
}

@media screen and (max-width: 800px) {
  .TechCard {
    width: 5.5rem;
    height: 5.5rem;
  }
}
