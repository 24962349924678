.TypewriterBar {
  color: var(--accent);
  font-weight: 400;
  animation: blink 0.75s infinite;
  margin-left: -0.3rem;
}

@keyframes blink {
  0% {
    color: var(--accent);
  }
  50% {
    color: var(--bg);
  }
}
